import './Hero.scss';
import BoxAnimation from '../Animations/BoxAnimation/BoxAnimation';

export default function Hero () {
    return (
        <section className="hero">
        <svg width="66" height="65" viewBox="0 0 66 65" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M40.8148 53.1943L40.394 52.6812L39.7688 52.9036C34.4989 54.7695 28.7115 54.5249 23.6178 52.2209C18.5241 49.917 14.5189 45.7322 12.4405 40.5424C10.362 35.3526 10.3713 29.5601 12.4664 24.377C14.5615 19.194 18.5801 15.0221 23.6812 12.7345L24.2393 12.4845V1.03955L23.0199 1.41188C15.5677 3.68465 9.17997 8.55879 5.02022 15.1464C0.86047 21.734 -0.794439 29.5967 0.356944 37.3022C1.50833 45.0077 5.38939 52.0432 11.2931 57.127C17.1969 62.2109 24.7305 65.0049 32.5215 65C34.956 65.0007 37.3831 64.7294 39.7573 64.191C42.0578 63.6672 44.294 62.8933 46.426 61.883L47.5176 61.3656L46.7514 60.4315L40.8148 53.1943Z"/>
            <path d="M62.4669 19.8492C59.9824 13.9612 55.8159 8.93709 50.4891 5.40627C45.1623 1.87545 38.9121 -0.00515068 32.5214 1.05953e-05C30.9219 -3.52104e-05 29.3246 0.116613 27.7421 0.349027L26.936 0.467941V49.8699C26.936 49.8699 30.5581 51.8241 32.6418 50.5441L36.4236 43.6326L42.9683 51.4916L42.9271 51.5165L43.6127 52.3525L49.3361 59.3301L49.8795 59.9926L50.5914 59.5156C53.5759 57.5119 56.2104 55.0307 58.3893 52.1717L51.1679 43.5004L51.1664 43.5029L45.0398 36.1461L51.5503 27.4555C51.5503 27.4555 51.5711 25.7603 48.9656 21.6112C46.6294 17.8911 44.9827 17.1885 44.9827 17.1885L38.3474 26.0459V11.6452C41.3072 12.4759 44.0564 13.9267 46.4125 15.9013C48.7686 17.8758 50.6778 20.3289 52.0134 23.0978C53.3489 25.8666 54.0803 28.8879 54.1588 31.961C54.2374 35.0341 53.6614 38.0888 52.4691 40.9223C52.4513 40.9643 52.4324 41.0056 52.4144 41.0475L59.8921 50.0268C60.3071 49.3807 60.6991 48.7202 61.0682 48.0455C63.4015 43.7516 64.7387 38.9876 64.9807 34.1067C65.2227 29.2258 64.3634 24.3529 62.4664 19.8492H62.4669Z"/>
        </svg>                
        <h1>My name is Cassy<br/><span>And I build Websites</span></h1>
        <p>Front End Web Developer / Designer</p>
        <BoxAnimation color='purple' size='lg' />
        <BoxAnimation color='blue' size='md' />
    </section>
    )
}